import loadQuestions from './qwiz';
import Translator from './translator';

var translator = new Translator({
  persist: true,
  languages: ['ru', 'en', "pl"],
  defaultLanguage: 'ru',
  detectLanguage: true,
  filesLocation: '/locate',
});

translator.load();

document.querySelector('form').addEventListener('click', function (evt) {
  if (evt.target.tagName === 'INPUT') {
    translator.toggle(evt.target.value);
    location.reload();
  }
});
